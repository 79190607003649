import React, { useEffect } from "react"
import ReactHtmlParser from "react-html-parser"
import Layout from "../components/layout"
import _ from "lodash"
import mapImage from "../../static/img/image_2022_03_30T04_44_31_489Z.png"
import playIcon from "../../static/img/play-icon.svg"

const contentful = require("contentful")
const client = contentful.createClient({
  space: "qvakacqlqafz",
  accessToken: "5tDByYQwl3Ld-uJmqj4BJvBvMDx2N5f9gu0PRXJbwEM",
})

const CaseStudiesPage = ({ data }) => {
  const ref = React.useRef()
  const [playing, setPlaying] = React.useState(false)
  const [environmentalPosts, setEnvironmentalPosts] = React.useState([])
  useEffect(async () => {
    const response = await client.getEntries({
      content_type: "environmentalPosts",
      order: "fields.title",
    })
    setEnvironmentalPosts(response.items)
  }, [])
  console.log(
    environmentalPosts,
    "environmentalPosts",
    environmentalPosts.find((post) => post.fields.featured)
  )
  return (
    <Layout page="environmental" positionDetail={true}>
      <div class="environment-map-container position-relative">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="emc-box d-flex">
                <img src={mapImage} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {environmentalPosts.find((post) => post.fields.featured) && (
        <div class="environnment-card-hero">
          <div class="environnment-card-hero-upper">
            <div class="ech-half ech-left">
              <img
                src={
                  environmentalPosts.find((post) => post.fields.featured).fields
                    .featuredImage
                    ? environmentalPosts.find((post) => post.fields.featured)
                        .fields.featuredImage.fields.file.url
                    : ""
                }
                alt=""
              />
            </div>
            <div class="ech-half ech-right">
              <video
                loop
                ref={ref}
                onClick={(e) => {
                  e.preventDefault()
                  setPlaying(!playing)
                  if (playing) {
                    ref.current.pause()
                  } else {
                    ref.current.play()
                  }
                }}
              >
                <source
                  src={
                    environmentalPosts.find((post) => post.fields.featured)
                      .fields.video
                      ? environmentalPosts.find((post) => post.fields.featured)
                          .fields.video.fields.file.url
                      : ""
                  }
                  type="video/mp4"
                />
              </video>
              {!playing && (
                <a
                  href="#!"
                  class="video-play"
                  onClick={(e) => {
                    e.preventDefault()
                    setPlaying(!playing)
                    if (playing) {
                      ref.current.pause()
                    } else {
                      ref.current.play()
                    }
                  }}
                >
                  <img src={playIcon} alt="" />
                </a>
              )}
            </div>
          </div>
          <div class="environnment-card-hero-lower">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="ech-text-box position-relative">
                    <div class="ech-logo-area d-flex align-items-center justify-content-center">
                      <img
                        src={
                          environmentalPosts.find(
                            (post) => post.fields.featured
                          ).fields.logo
                            ? environmentalPosts.find(
                                (post) => post.fields.featured
                              ).fields.logo.fields.file.url
                            : ""
                        }
                        alt=""
                      />
                    </div>
                    <h4>
                      {
                        environmentalPosts.find((post) => post.fields.featured)
                          .fields.title
                      }
                    </h4>
                    <p>
                      {ReactHtmlParser(
                        environmentalPosts
                          .find((post) => post.fields.featured)
                          .fields.description.replaceAll("\n", "<br/>")
                      )}
                    </p>
                    <h5>
                      Sponsor :{" "}
                      <span>
                        {
                          environmentalPosts.find(
                            (post) => post.fields.featured
                          ).fields.sponsor
                        }
                      </span>
                    </h5>
                    <a
                      style={{ textDecoration: "none", color: "#fff" }}
                      href={
                        environmentalPosts.find((post) => post.fields.featured)
                          .fields.link
                      }
                      target="_blank"
                    >
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div class="environnment-card-container">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="new-card-header text-center">
                <h3>Environmental Inclusion</h3>
                <p>
                  Environmental inclusion is Salvex's groundbreaking
                  sustainability program to reduce global carbon emissions and
                  support planetary wildlife. We achieve our environmental goals
                  by trading industrial products in the global circular economy,
                  and through donations and support for renowned environmental
                  organizations.
                </p>
                <p>
                  Salvex's business thrives on cultural and environmental
                  inclusion. We embrace 50 different nationalities and cultures
                  in our global workforce -- diversity that helps us better
                  understand local markets and trade environments. Our
                  environmental program takes this cultural inclusivity one step
                  further, to save animals and their habitats. Each of our
                  diverse staff members has chosen an environmental organization
                  to be the recipient of donations that result from our sales of
                  our industrial products. Every sale we successfully complete
                  generates a new donation to one of the Salvex environmental
                  grantees.
                </p>
                <p>
                  Salvex is a global company, not only because we hire people
                  around the world to sell distressed assets and reduce waste on
                  our planet, but because we support animals and the natural
                  world directly through our sales process, generating donations
                  and support for environmental organizations' crucial work.
                  Salvex represents the earth, and we aim to move the needle on
                  sustainability one step at a time, pursuing environmental
                  inclusion as a natural extension of our cultural diversity.
                </p>
              </div>
              <ul class="new-card-list d-flex flex-wrap">
                {environmentalPosts.map((ed) => (
                  <li class="new-card-item">
                    <div class="new-card-box border-radius-4 overflow-hidden d-flex flex-column w-100 h-100">
                      <div class="new-card-img-box">
                        <img
                          style={{ maxWidth: "100%" }}
                          src={
                            ed.fields.featuredImage
                              ? ed.fields.featuredImage.fields.file.url
                              : ""
                          }
                          alt=""
                        />
                      </div>
                      <div class="new-card-text-box  position-relative">
                        <div class="card-logo-area d-flex align-items-center justify-content-center">
                          <img
                            style={{ maxWidth: "100%" }}
                            src={
                              ed.fields.logo
                                ? ed.fields.logo.fields.file.url
                                : ""
                            }
                            alt=""
                          />
                        </div>
                        <h4>{ed.fields.title}</h4>
                        <p>
                          {ReactHtmlParser(
                            ed.fields.description.replaceAll("\n", "<br/>")
                          )}
                        </p>
                      </div>

                      <div class="new-card-bottom mt-auto">
                        {ed.fields.sponsor && (
                          <h5>
                            Sponsor : <span>{ed.fields.sponsor}</span>
                          </h5>
                        )}
                        <a href={ed.fields.link} target="_blank">
                          Learn More
                        </a>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default CaseStudiesPage
